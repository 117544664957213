export type SendMessage =
  | { message: "connect" }
  | { message: "enable-price-locking"; lockRequestLabel?: string }
  | { message: "enable-float-requests" }
  | { message: "set-fields"; fields: FieldMapping[] }
  | {
      message: "log-in";
      clientAccessId: string;
      emailAddress: string;
      password: string;
    }
  | {
      message: "set-pipeline-record-id";
      pipelineRecordId: string;
      overrideCreditApplicationFields: FieldMapping[];
    }
  | {
      message: "create-pipeline-record";
      pipelineFieldValues: FieldMapping[];
    }
  | {
      message: "set-version";
      version: string;
    };

export type ReceiveMessage = {
  message: "price-lock";
  product: PriceLockProduct;
  scenario: PriceLockScenario;
};

export interface FieldMapping<Value extends FieldValue | null = FieldValue> {
  fieldId: string;
  value: Value;
}

export type FieldValue =
  | { type: "number"; value: string }
  | { type: "enum"; enumTypeId: string; variantId: string }
  | { type: "string"; value: string }
  | { type: "duration"; unit: DurationUnit; count: string };

export type FieldType = FieldValue["type"];

export const FIELD_TYPES: FieldType[] = [
  "number",
  "enum",
  "string",
  "duration",
];

export type DurationUnit =
  | "days"
  | "weeks"
  | "fortnights"
  | "half-months"
  | "months"
  | "quarters"
  | "years";

export const DURATION_UNITS: DurationUnit[] = [
  "days",
  "weeks",
  "fortnights",
  "half-months",
  "months",
  "quarters",
  "years",
];

export interface PriceLockProduct {
  id: string;
  name: string;
}

export interface PriceLockScenario {
  status: string;
  adjustedRate: string;
  adjustedPrice: string;
  priceScenarioFields: FieldMapping<FieldValue | null>[];
  calcluatedFields: FieldMapping<FieldValue | null>[];
}

export const EXAMPLE_FIELDS: FieldMapping<FieldValue>[] = [
  {
    fieldId: "field@occupancy-type",
    value: {
      type: "enum",
      enumTypeId: "occupancy-type",
      variantId: "primary-residence",
    },
  },
  {
    fieldId: "field@desired-loan-term",
    value: {
      type: "duration",
      unit: "years",
      count: "15",
    },
  },
  {
    fieldId: "field@number-of-units",
    value: {
      type: "number",
      value: "1",
    },
  },
];
