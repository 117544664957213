import React, { useCallback, useState } from "react";
import { SendMessage } from "../../protocol";

interface EnablePriceLockingProps {
  onSendMessage(message: SendMessage): void;
}

export const EnablePriceLocking: React.FC<EnablePriceLockingProps> = (
  props
) => {
  const { onSendMessage } = props;

  const [lockRequestLabel, setLockRequestLabel] = useState<
    string | undefined
  >();
  const handleEnablePriceLocking: React.FormEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      onSendMessage({ message: "enable-price-locking", lockRequestLabel });
    },
    [onSendMessage, lockRequestLabel]
  );

  return (
    <details>
      <summary>Enable Price Locking</summary>
      <form onSubmit={handleEnablePriceLocking}>
        <label>
          <input
            type="text"
            placeholder="Lock Request"
            value={lockRequestLabel ?? ""}
            onChange={(e) => {
              if (e.target.value === "") {
                setLockRequestLabel(undefined);
              } else {
                setLockRequestLabel(e.target.value);
              }
            }}
          />
        </label>
        <button type="submit">Enable Price Locking</button>
      </form>
    </details>
  );
};
