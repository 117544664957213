import React, { useCallback, useState } from "react";
import { SendMessage } from "../../protocol";

interface EnablePriceLockingProps {
  onSendMessage(message: SendMessage): void;
}

export const SetVersion: React.FC<EnablePriceLockingProps> = (props) => {
  const { onSendMessage } = props;

  const [version, setVersion] = useState<string | undefined>();
  const handleEnablePriceLocking: React.FormEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      onSendMessage({ message: "set-version", version: version || "current" });
    },
    [onSendMessage, version]
  );

  return (
    <details>
      <summary>Set Version</summary>
      <form onSubmit={handleEnablePriceLocking}>
        <label>
          <input
            type="text"
            placeholder="Version"
            value={version ?? ""}
            onChange={(e) => {
              if (e.target.value === "") {
                setVersion(undefined);
              } else {
                setVersion(e.target.value);
              }
            }}
          />
        </label>
        <button type="submit">Set Version</button>
      </form>
    </details>
  );
};
